import "./index.scss"
import React from "react"
import { Link } from "gatsby"
import Layout from "../../layouts/default"
import SEO from "../../components/seo"
import FAQ from "./components/FAQ"
import Educational from "./components/educational"
import { PricingTable } from './components/pricing-table'
import { stripe_pricing_table, stripe_public } from '../../constants'
import OnshapeSubsidy from "./components/onshapeSubsidy"

declare global {
    namespace JSX {
      interface IntrinsicElements {
        'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
    }
}

export default function PricingPage() {
  return (
    <Layout>
      <SEO title="Choose your Phi plan" />

      <main className="pricing">
      {/* <h2 class="h3yellow"><b>SPECIAL PROMO:</b> Apply promotion code <b>PROMO50</b> at checkout and get <b>50% discount</b> post trial!</h2>  */}
        <header>
          <Header/>
        </header>

        <PricingTable public_key={stripe_public} table_id={stripe_pricing_table} />
        
        <section className="pricing-footnote">
          <footer>
              <br></br>
              <p>&emsp;&emsp;&emsp;* For Onshape users only</p>
          </footer>
        </section>

        <OnshapeSubsidy />

        <Educational />

        <section className="need-help">
          <h3>Need help deciding?</h3>
          <p>
            <Link to="/contact" title='Contact us'>Contact us</Link> so we can help
          </p>
        </section>

        {/* <section className="pricing-faq">
          <h2>FAQ</h2>
          <FAQ />
        </section> */}
      </main>
    </Layout>
  )
}

function Header() {
  return <div> <h2 className='huge'>Start a 7 Day Trial Today</h2><h3>Select any of the subscription plans available to start a 7 Day Trial without a payment card required.</h3> </div>
}
